<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Layout Fonts'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Layout Font Edit'"
      :title="'Layout Font Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">Load Font File</p>
          <div class="input-container upload-instruction">
            <input
              @change="handleLoadFile"
              type="file"
              name="file"
              id="file"
              accept="*,.ttf,.eof,.woff,.woff2"
            />
            <label
              v-if="formDataToSend.file == null && formDataToSend.file_name == null"
              class="upload-instruction__button"
              for="file"
              >Load</label
            >
            <template v-else>
              <div v-if="formDataToSend.file">{{ formDataToSend.file.name }}</div>
              <div v-if="formDataToSend.file_name">{{ formDataToSend.file_name }}</div>

              <div class="form-create__load-buttons">
                <label class="upload-instruction__button" for="file">Change</label>
              </div>
            </template>
            <Notification :error-messages="validationErrors['file']" />
          </div>

          <p class="form-create__label">Image font</p>

          <div class="input-container">
            <div class="form-create__images">
              <div class="form-create__image">
                <template v-if="formDataToSend.image_name">
                  <img
                    :src="formDataToSend.image_name"
                    class="form-create__image-load"
                    alt="load"
                  />
                  <div @click="handleDeleteImage()" class="form-create__change-image">
                    <RestoreIcon />
                  </div>
                </template>
                <template v-else>
                  <picture-input
                    v-if="formDataToSend.img == null"
                    :ref="`img`"
                    @change="handleChoosePhoto()"
                    accept="image/jpeg,image/png"
                    size="100"
                    width="200"
                    height="80"
                    :name="`img`"
                    buttonClass="change-image__button"
                    :customStrings="{ drag: '+', change: 'Change' }"
                  />
                  <img
                    v-if="formDataToSend.img != null"
                    :id="`blah`"
                    src="#"
                    class="form-create__image-load"
                    alt="load"
                  />
                  <div
                    @click="handleDeleteImage()"
                    v-if="formDataToSend.img != null"
                    class="form-create__change-image"
                  >
                    <RestoreIcon />
                  </div>
                </template>
              </div>
            </div>

            <Notification :error-messages="validationErrors['image']" />
          </div>

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";
import PictureInput from "vue-picture-input";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import Notification from "~/components/molecules/Notification.vue";

import RestoreIcon from "~/assets/images/icons/restotre.svg";

export default {
  name: "TemplatesFontEdit",
  metaInfo: {
    title: "Templates Font Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        file: null,
        img: null,
        file_name: null,
        image_name: null,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    RestoreIcon,
    PictureInput,
    Notification,
  },
  mounted() {
    this.getItem();
  },
  computed: {
    ...mapState("references", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.LAYOUT_FONTS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = new FormData();
      data.append("name", this.formDataToSend.name.trim());
      if (this.formDataToSend.file) {
        data.append("file", this.formDataToSend.file);
      }
      if (this.formDataToSend.img) {
        data.append("image", this.formDataToSend.img);
      }

      const url = `/${this.getId}?_method=put`;
      this.$store.dispatch("references/editLayoutFonts", { url: url, editData: data });
    },
    handleLoadFile() {
      this.formDataToSend.file = document.querySelector(`input[name="file"]`).files[0];
      this.formDataToSend.file_name = null;
    },
    handleChoosePhoto() {
      setTimeout(() => {
        this.formDataToSend.image_name = null;

        this.formDataToSend.img = document.querySelector(`input[name="img"]`).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.img);
      }, 0);
    },
    handleDeleteImage() {
      this.formDataToSend.img = null;
      this.formDataToSend.image_name = null;
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.LAYOUT_FONTS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        referencesApi.getItemLayoutFonts(url).then((res) => {
          this.$store.commit("references/setError", {});
          this.$store.commit("references/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.file_name = data.fileName;
          this.formDataToSend.image_name = data.imageName;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-create {
  &__load-buttons {
    display: flex;
    gap: 10px;
  }

  &__images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    position: relative;
    width: 200px;
    height: 80px;
    margin: 0 30px 45px 0;
    border: 1px solid $mainDisabled;
  }

  &__delete-image {
    position: absolute;
    top: -19px;
    right: -26px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &__add-image {
    position: absolute;
    top: -19px;
    right: -8px;
    cursor: pointer;
    transform: rotate(45deg);

    svg {
      path {
        fill: $mainSecondColor;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-load {
    width: 200px;
    height: 80px;
    object-fit: cover;
  }

  &__change-image {
    position: absolute;
    bottom: -40px;
    left: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: $mainColor;
    border-radius: 50px;
    cursor: pointer;

    svg {
      path {
        fill: $g-color-white;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
